import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Navbar } from '../../../shared'

export default ({ logo, hasScrolled }) => {
  return (
    <StaticQuery
      query={graphql`
        query CategoriesQuery {
          site {
            siteMetadata {
              title
            }
          }
          allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___tags) {
              fieldValue
              totalCount
            }
          }
        }
      `}
      render={data => (
        <Navbar data={data} logo={logo} hasScrolled={hasScrolled} centered />
      )}
    />
  )
}
