const theme = {
  colors: {
    body: '#f7f7f7',
    dark: '#040404',
    ctaBlue: '#041C95',
    ctaBlueHover: '#00037C',
    light: '#ffffff',
    navActive: '#598FE7;', 
    popularBackground: '#F9DEC9',
    popularText: '#040404',
    emailBanner: '#AEC5EB',
    topRated: '#EA8F49',
    bestValue: '#598FE6',
    bestOfBest: '#df6b26',
    bestForBudgets: '#0097fe',
    tableHeader: '#AEC5EB',
    socialShare: '#A3A2A2',
    blogDate: '#9E9E9E',
    border: '#E5E5E5',
    footer: '#598FE6',
  },
  fonts: {
    title: "'Brandon', Helvetica, sans-serif",
    boldTitle: "'Brandon-Black', Helvetica, sans-serif",
    primary: "'Larsseit', sans-serif",
    boldPrimary: "'Larsseit-Bold', sans-serif",
  },
}

export default theme
